import React from "react";

export default function Header() {

	return (
		<div>
			<div className='page-banner'>
				<img src='/logo.png' alt='Logo'/>
				<h1>FlightDeck</h1>
			</div>
		</div>
	)

}
